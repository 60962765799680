import useSWR from 'swr'
import {
  productsDetails,
  productsAlternative,
  products,
  productUrl,
} from './../Api/products.api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ICartRequest, IProducts } from '../Api/interface'
import { ICartHandler, useCart } from './handleCart'

export type IHandleProduct = {
  data: IProducts[]
  loading: boolean
  cartLoading: boolean
} & ICartHandler

interface IProductParams {
  slug: string
}

type IUseProducts = {
  data: IProducts | undefined
  alternativeProducts: IProducts[]
  loading: boolean
  cartLoading: boolean
} & ICartHandler

interface IHandleCallback {
  in_cart: boolean
}

export const useProductHandler = (): IHandleProduct => {
  const { data, isLoading } = useSWR(productUrl, products)

  const {
    addToCart,
    deleteCartItem,
    updateCartItem,
    searchCart,
    getCartItem,
    cartItems,
    loading: cartLoading,
    totalCost,
  } = useCart()

  const [productsItems, setItems] = useState<IProducts[]>([])

  const InitProdItems = useCallback(() => {
    if (!isLoading) {
      const productItems = data?.results as IProducts[]
      setItems(productItems)
    }
  }, [isLoading])

  const handleCallBack = useCallback(
    (item: ICartRequest, attribute: IHandleCallback) => {
      setItems((prev) => [
        ...prev.slice(
          0,
          prev.findIndex((el) => el.id === item.product_id)
        ),
        Object.assign(
          {},
          prev[prev.findIndex((el) => el.id === item.product_id)],
          { ...attribute, quantity_in_cart: item.quantity }
        ),
        ...prev.slice(prev.findIndex((el) => el.id === item.product_id) + 1),
      ])
    },
    []
  )

  const addItemToCart = useCallback(
    (item: ICartRequest) => {
      addToCart(item, handleCallBack as any)
    },
    []
  )
  const updateItemInCart = useCallback(
    (item: ICartRequest, id: number) => {
      updateCartItem(item, id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )
  const deleteItemInCart = useCallback(
    (id: number) => {
      deleteCartItem(id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )

  useEffect(() => {
    InitProdItems()
  }, [InitProdItems])

  return useMemo(
    () => ({
      data: productsItems,
      loading: isLoading,
      addToCart: addItemToCart,
      deleteCartItem: deleteItemInCart,
      updateCartItem: updateItemInCart,
      searchCart,
      getCartItem,
      cartLoading,
      cartItems,
      totalCost,
    }),
    [
      productsItems,
      isLoading,
      cartItems,
      cartLoading,
      addToCart,
      deleteCartItem,
      updateCartItem,
      searchCart,
      getCartItem,
      totalCost,
    ]
  )
}

export const useProducts = ({ slug }: IProductParams): IUseProducts => {
  const {
    getCartItem,
    addToCart,
    deleteCartItem,
    updateCartItem,
    cartItems,
    searchCart,
    totalCost,
    loading: cartLoading,
  } = useCart()
  const [loading, setLoading] = useState<boolean>(true)
  const [result, setResult] = useState<IProducts | undefined>(undefined)
  const [alternative, setAlternative] = useState<IProducts[]>([])

  const loadProducts = useCallback(async () => {
    const prod = await productsDetails(slug)
    setResult(prod)
  }, [])

  const handleCallBack = useCallback(
    (item: ICartRequest, attribute: IHandleCallback) => {
      // setResult((prev) => ({...prev,  quantity_in_cart: item.quantity }));
      setAlternative((prev) => [
        ...prev.slice(
          0,
          prev.findIndex((el) => el.id === item.product_id)
        ),
        Object.assign(
          {},
          prev[prev.findIndex((el) => el.id === item.product_id)],
          { ...attribute, quantity_in_cart: item.quantity }
        ),
        ...prev.slice(prev.findIndex((el) => el.id === item.product_id) + 1),
      ])
    },
    []
  )

  const loadAlternative = useCallback(async () => {
    try {
      setLoading(true)
      const alt = await productsAlternative(slug)
      const { results } = alt
      setAlternative(results)
      setLoading(false)
    } catch (e: any) {
      console.error(e.message)
      throw e
    }
  }, [])

  const addItemToCart = useCallback(
    (item: ICartRequest) => {
      addToCart(item, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )
  const updateItemInCart = useCallback(
    (item: ICartRequest, id: number) => {
      updateCartItem(item, id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )
  const deleteItemInCart = useCallback(
    (id: number) => {
      deleteCartItem(id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )

  useEffect(() => {
    void loadProducts()
    void loadAlternative()
  }, [])

  return useMemo(
    () => ({
      data: result,
      alternativeProducts: alternative.filter((el) => el.SKU !== result?.SKU),
      loading: result === undefined || loading,
      getCartItem,
      addToCart: addItemToCart,
      deleteCartItem: deleteItemInCart,
      updateCartItem: updateItemInCart,
      searchCart,
      cartLoading,
      cartItems,
      totalCost,
    }),
    [result === undefined || loading, cartItems, cartLoading]
  )
}
