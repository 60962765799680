import { APIResponse, ICartRequest } from './interface'
import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'
import { ICartGetBody } from '../hooks/handleCart';

export const cartUrl = '/shopping/cart/cart-item'

export const cartList = async (url: string): Promise<APIResponse> => {
  try {
    const resp: AxiosResponse = await AxiosConfig.get(url);
    return resp.data;
  } catch (error: any) {
    console.error(error?.response.data.detail);
    throw error; 
  }
};

export const getCartItemById = async (id: number): Promise<ICartGetBody> => {
  try {
    const resp: AxiosResponse = await AxiosConfig.get(`${cartUrl}/${id}`);
    return resp.data;
  } catch (error: any) {
    console.error(error?.response?.data?.detail ?? "Failed to fetch cart item");
    throw error;
  }
};

export const addToCartList = (request: ICartRequest): any =>
  AxiosConfig.post(cartUrl, request).then((resp: AxiosResponse) => resp.data)

export const updateCart = (request: ICartRequest, productId: number): any =>
  AxiosConfig.patch(`${cartUrl}/${productId}`, request).then(
    (resp: AxiosResponse) => resp.data
  )

export const DeleteCartItem = (productId: number): any =>
  AxiosConfig.delete(`${cartUrl}/${productId}`).then(
    (resp: AxiosResponse) => resp.data
  )
