import useSWR from 'swr'
import { orderUrl, submitOrder, userOrders } from './../Api/orders.api'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ICreateOrder, IOrders } from '../Api/interface'
import { useUser } from './useUser'
import { usePaystackPayment } from 'react-paystack'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { handleError } from '../utilities/errorMessageHandler'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ICartHandler, useCart } from './handleCart'

type IField = 'payment_method' | 'transaction_no' | 'shipping'

interface IPayStack {
  message: string
  redirecturl: string
  reference: string
  status: string
  trans: string
  transaction: string
  trxref: string
}

interface IPayStackConfig {
  reference: string
  email: string
  amount: number
  publicKey: string
}

type IOrder = {
  createOrder: (payload: ICreateOrder, amount: number) => void
  data: IOrders[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: ICreateOrder
  setDefaultAddress: (id: number) => void
  updateForm: (field: IField, e: ChangeEvent<HTMLInputElement>) => void
  loading: boolean
  canProceed: boolean
} & ICartHandler

export const useOrder = (): IOrder => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchParams] = useSearchParams();
  const { data: orders, isLoading } = useSWR(`${orderUrl}?page=${pageIndex}`, userOrders)
  const { data } = useUser()
  const {
    totalCost,
    cartItems,
    getCartItem,
    addToCart,
    loading,
    updateCartItem,
    deleteCartItem,
    searchCart,
  } = useCart()
  const navigate = useNavigate();

  useEffect(() => {
    const pageParam = searchParams.get('page');
    const pageNumber = (pageParam != null) ? parseInt(pageParam, 10) : 1;
    setPageIndex(pageNumber);
  }, [searchParams]);

  const goToNextPage = (): void => {
    if (orders?.next !== null) {
      setPageIndex((prev) => {
        const nextPageIndex = prev + 1;
        navigate(`?page=${nextPageIndex}`);
        return nextPageIndex;
      });
    }
  };

  const goToPrevPage = (): void => {
    if (orders?.previous !== null) {
      setPageIndex((prev) => {
        const prevPageIndex = prev - 1;
        navigate(`?page=${prevPageIndex}`);
        return prevPageIndex;
      });
    }
  };

  const [orderList, setOrders] = useState<IOrders[]>([])
  const [config, setConfig] = useState<IPayStackConfig>({
    amount: totalCost,
    email: '',
    publicKey: '',
    reference: '',
  })
  const [form, setForm] = useState<ICreateOrder>({
    order_type: 'drugstoc',
    payment_method: '',
    shipping: 0,
    transaction_no: '',
  })

  const initOrders = useCallback(() => {
    if (orders !== undefined) {
      const response = orders.results as IOrders[]
      setOrders(response)
    }
  }, [orders])

  const initPaystack = useCallback(() => {
    if (data !== undefined) {
      setConfig((prev) => ({
        ...prev,
        amount: totalCost * 100,
        email: data.email,
        publicKey: 'pk_live_865c6f8a4e510be0fe7a1f5c1c00d63a2f4a5499',
        reference: new Date().getTime().toString(),
      }))
    }
    // }, [form, data, config, totalCost]
  }, [data, totalCost])

  const initializePayment = usePaystackPayment(config)

  const handleCreateOrder = (payload: ICreateOrder): void => {
    const addResp = submitOrder(payload)
    void toast.promise(addResp, {
      loading: 'Please wait ....',
      success: (resp) => {
        navigate('/orders')
        return 'Order Created Successfully'
      },
      error: (err: AxiosError) => {
        const { response } = err
        const message = handleError(response?.data)
        return `${message ?? 'Something went wrong try again'}`
      },
    })
  }

  const onSuccess = (reference: IPayStack): any => {
    setForm(prev => ({...prev, transaction_no: reference.trxref}));
    handleCreateOrder({...form, transaction_no: reference.trxref});
  }

  const updateForm = useCallback(
    (field: IField, e: ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({
        ...prev,
        [field]:
          field === 'shipping' ? parseInt(e.target.value) : e.target.value,
        transaction_no: e.target.value === 'drugstoc_credit' || e.target.value === 'drugstoc_pay' ? 'none' : ''
      }))

      if (e.target.value === 'drugstoc_credit') {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false)
      }
    },
    [form, isModalOpen]
  )

  const createOrder = useCallback(
    (payload: ICreateOrder, amount: number) => {
      if (payload.payment_method === 'card_payment') {
        initializePayment(onSuccess as any)
      } else {
        handleCreateOrder(form)
        setOrders([])
      }
    },
    [form, config]
  )

  const setDefaultAddress = useCallback(
    (id: number) => {
      setForm({ ...form, shipping: id })
    },
    [form]
  )

  const validForm = useCallback((): boolean => {
    if (form.payment_method === '' || form.shipping === 0) {
      return false
    } else {
      return true
    }
  }, [form.payment_method, form.shipping])

  useEffect(() => {
    initPaystack();
    initOrders();
  }, [initPaystack, initOrders, totalCost, form, data]);    

  return useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
      createOrder,
      form,
      setDefaultAddress,
      data: orderList,
      loading: isLoading,
      cartItems,
      cartLoading: loading,
      getCartItem,
      addToCart,
      updateCartItem,
      deleteCartItem,
      searchCart,
      totalCost,
      updateForm,
      canProceed: validForm(),
      orders,
      goToNextPage,
      goToPrevPage,
    }),
    [orderList, form, config, data, createOrder, cartItems, totalCost, validForm, isModalOpen, setIsModalOpen]
  )
}
