import { manufacturerDetail } from './../Api/manufacturer.api'
import { useCart, ICartHandler } from './handleCart'
import { IFilterOptions } from './../layout/Filters/interface'
import { SearchProductsManufacturer } from './../Api/productManufacturer.api'
import { SearchProductsCategory } from './../Api/productsCategory.api'
import useSWR from 'swr'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IProducts, ICartRequest, IManufacturers } from '../Api/interface'

interface IPageParams {
  title: string
  image: string
  count: number
}

type IProductResult = {
  data: any
  pageInfo: IPageParams
  filter: IFilterOptions[]
  handleFilter: (filter: IFilterOptions) => void
  checked: (filter: IFilterOptions) => boolean
  loading: boolean
  cartLoading: boolean
  error: any
  brand: IManufacturers | undefined
  addToCart: (item: ICartRequest) => void
  productList?: any
  products?:any
  goToNextPage?:any
  goToPreviousPage?:any
  currentPage?:any
  totalPages?:any
  filteredData?: any
  uniqueSearchedManufacturerData?: any
  uniqueSearchedCompositionData?: any
  uniqueSearchedCategoryData?: any
} & ICartHandler

interface IProductsParams {
  params: string
  slug?: string
  type: 'category' | 'manufacturer'
  category?: string
}

interface IHandleCallback {
  in_cart: boolean
}

export const useProductsResult = ({
  params,
  type,
  slug,
}: IProductsParams): IProductResult => {
  const [currentPage, setCurrentPage] = useState(1)
  const { data, error, isLoading }: any = useSWR(
    type === 'category'
      ? `/inventory/products/category/${params}?page=${currentPage}`
      : `/inventory/products?manufacturer=${params}&page=${currentPage}`,
    type === 'category' ? SearchProductsCategory : SearchProductsManufacturer
  );

  const {
    getCartItem,
    addToCart,
    deleteCartItem,
    updateCartItem,
    cartItems,
    totalCost,
    searchCart,
    loading: cartLoading,
  } = useCart()
  const [result, setResult] = useState<IProducts[]>([])
  const [pageInfo, setPageInfo] = useState<IPageParams>({
    count: 0,
    image: '',
    title: '',
  })

  const ITEMS_PER_PAGE = 20
  const totalCount = data?.data?.count
  
  const goToNextPage = (): void => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const goToPreviousPage = (): void => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE)

  const [filter, setFilter] = useState<IFilterOptions[]>([])
  const [brand, setBrand] = useState<IManufacturers | undefined>(undefined)
  const [productList, setProductList] = useState<any>({})

  const handleDataInit = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!isLoading) {
      const resp = data?.data.results as IProducts[]
      const product = data?.data
      setPageInfo({
        image: '',
        count: data?.data.count as number,
        title: type === 'category' ? resp[0].category : resp[0]?.manufacturer,
      })
      if (slug !== undefined) {
        const brandData = await manufacturerDetail(slug)
        setBrand(brandData)
      }
      setResult(resp)
      setProductList(product)
    }
  }, [isLoading])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleQuery = async (arr: IFilterOptions[]): Promise<void> => {
    const resp = arr.map((_el) => `${_el.params}=${_el.value}`)
    const dataQuery = resp.join('&')
    if (type === 'category') {
      const searchResult = await SearchProductsCategory(
        `/inventory/products/category/${params}?${dataQuery}`
      )
      const response = searchResult?.data.results as IProducts[]
      setResult(response)
    }

    if (type === 'manufacturer') {
      const searchResult = await SearchProductsManufacturer(
        `/inventory/products?manufacturer=${params}&${dataQuery}`
      )
      const response = searchResult?.data.results as IProducts[]
      setResult(response)
    }
  }

  const handleFilter = useCallback(
    (option: IFilterOptions) => {
      const existedValue = filter.find((el) => el.value === option.value)
      if (existedValue != null) {
        const newData = filter.filter((el) => el.value !== option.value)
        // void handleQuery(newData)
        setFilter(newData)
      } else {
        const newData = [...filter, option]
        // void handleQuery(newData)
        setFilter(newData)
      }
    },
    [filter]
  )

  const isChecked = useCallback(
    (option: IFilterOptions): boolean => {
      const existedValue = filter.find((el) => el.value === option.value)
      if (existedValue != null) {
        return true
      }

      return false
    },
    [filter]
  )

  const handleCallBack = useCallback(
    (item: ICartRequest, attribute: IHandleCallback) => {
      setResult((prev) => [
        ...prev.slice(
          0,
          prev.findIndex((el) => el.id === item.product_id)
        ),
        Object.assign(
          {},
          prev[prev.findIndex((el) => el.id === item.product_id)],
          { ...attribute, quantity_in_cart: item.quantity }
        ),
        ...prev.slice(prev.findIndex((el) => el.id === item.product_id) + 1),
      ])
    },
    []
  )

  const addItemToCart = useCallback(
    (item: ICartRequest) => {
      addToCart(item, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )
  const updateItemInCart = useCallback(
    (item: ICartRequest, id: number) => {
      updateCartItem(item, id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )
  const deleteItemInCart = useCallback(
    (id: number) => {
      deleteCartItem(id, handleCallBack as any)
    },
    [cartLoading, cartItems]
  )

  useEffect(() => {
    void handleDataInit()
  }, [isLoading])

  return useMemo(
    () => ({
      data: result,
      loading: isLoading,
      cartItems,
      getCartItem,
      addToCart: addItemToCart,
      deleteCartItem: deleteItemInCart,
      searchCart,
      updateCartItem: updateItemInCart,
      filter,
      totalCost,
      cartLoading,
      checked: isChecked,
      pageInfo,
      brand,
      handleFilter,
      error,
      productList,
      totalPages,
      goToPreviousPage,
      goToNextPage,
      products: data?.data,
      currentPage,
    }),
    [result, brand, isLoading, error, filter, cartItems, cartLoading, currentPage]
  )
}
